const Dashboard = () => {
  return (
    <div className="w-full h-full">
      <p className="text-3xl font-bold tracking-widest ml-3 text-red-600">
        Trang quản lý
      </p>
    </div>
  );
};

export default Dashboard;
