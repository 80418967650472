const navConfig = [
  {
    title: "Bảng điều khiển",
    path: "/admin/dashboard",
  },
  {
    title: "Sản phẩm",
    path: "/admin/sanpham",
  },
  {
    title: "Loại sản phẩm",
    path: "/admin/loaisanpham",
  },
];

export default navConfig;
